<template>
    <div>
        <div class="header_common_box" style="position:fixed;width: 100%;">
            <v-header :params="{ title, leftIcon: true }"></v-header>
        </div>
        <div >
            <div class="contents" >
                <div v-for="(item,index) in masterinfo.listDetails" :key="index" >
                    <img  :src="item.imgUrl" @click="imgpreview(index)" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import { ImagePreview } from 'vant';

export default {
    components:{
        vHeader
    },
    data(){
        return{
            title: this.$route.meta.title,
            masterinfo:{}
        }
    },
    computed:{
        imgarray(){
            let list=[]
            this.masterinfo.listDetails.forEach(item => {
                list.push(item.imgUrl)
                
            })
            return list
        }
    },
    methods:{
        async getfamousDetail(){
            let data = {
                dashi_id: this.$route.query.id
                }
            const res = await this.$HTTP.get(this, this.$API['API_MASTER_DETAIL'], data, {headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    console.log('名人信息',res)
                                    this.masterinfo=res.data
                                }
        },
        imgpreview(index){
            // console.log(this.imgarray,index)
            
            ImagePreview({
                images: this.imgarray,
                startPosition: index,
                closeable: true,
            })
        }
    },
    mounted(){
        this.getfamousDetail()
    }
}
</script>

<style lang="less" scoped>
.contents{
    white-space: nowrap;
    position: relative;
    top: 1.15rem;
    div{
        display: inline;
        img{
            margin-top: 0.3rem;
            width: 8rem;
            height: 12rem;
            padding: 0.1rem;
        }
    }
    
}
</style>